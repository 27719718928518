import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsPage from "./projects"
import featured from "../images/jade_thing.jpg"
import arrow from "../images/downarrow.svg"
//import { Controller, Scene } from "react-scrollmagic"
//import { Tween, Timeline } from "react-gsap"

import "../scss/index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Charles Chalas | Digital Designer & Developer" />
    <div className="feature" style={{ backgroundImage: `url(${featured})` }}>
      {/*<img src={featured} alt="featured" />*/}

      {/*<Controller>
        <Scene triggerHook="onLeave" duration="100%" pin>
          <Timeline wrapper={<div id="pinContainer" />}>
            <Tween from={{ x: "300%", y: "50%" }} to={{ x: "-100%" }}>
              <div style={{ width: "100%" }}>*/}
      <div className="featured_text">
        I am a multidisciplinary
        <br />
        <strong>digital designer</strong>, <strong>art director</strong>
        <br /> and
        <strong> developer</strong> based in Geneva, Switzerland.{" "}
        {/*<img src={flag} alt="Swiss flag" style={{ height: "50px" }} />*/}
      </div>
      {/*</div>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>*/}

      <img src={arrow} alt="down arrow" className="down_arrow" />
    </div>
    <ProjectsPage />

    {/*<Image />*/}
    {/*<Link to="/page-2/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage
